"use client";

import { Content } from "@prismicio/client";
import { PrismicRichText, SliceComponentProps } from "@prismicio/react";
import * as S from "./styles";
import { useRouter } from "next/navigation";
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious
} from "@/components/ui/carousel";

/**
 * Props for `MundorailClosedRoutes`.
 */
export type MundorailClosedRoutesProps =
  SliceComponentProps<Content.MundorailClosedRoutesSlice>;

/**
 * Component for "MundorailClosedRoutes" Slices.
 */
const MundorailClosedRoutes = ({
  slice
}: MundorailClosedRoutesProps): JSX.Element => {
  const router = useRouter();

  return (
    <S.Container
      data-slice-type={slice.slice_type}
      data-slice-variation={slice.variation}
    >
      <S.Header>
        <S.TitleImg field={slice.primary.title} alt="" id="garantizadas" />
        <S.Subtitle>
          <PrismicRichText field={slice.primary.subtitle} />
        </S.Subtitle>
      </S.Header>
      <S.CardsContainer>
        <Carousel className="w-full">
          <CarouselPrevious
            className="absolute top-1/2 -translate-y-1/2 left-4 z-20 disabled:hidden peer/prev"
            variant="bg"
          />
          <div className="md:flex opacity-100 h-full w-1/2 bg-gradient-to-r from-white to-transparent absolute top-0 left-0 z-10 peer-disabled/prev:opacity-0 transition-opacity ease-in-out pointer-events-none" />
          <CarouselContent className="w-full">
            {slice.primary.images.map(({ image, uid }, index) => (
              <CarouselItem key={index} className="max-w-[325px]">
                <S.Card
                  field={image}
                  onClick={() => router.push(`/${uid}`)}
                  alt=""
                />
              </CarouselItem>
            ))}
          </CarouselContent>
          <CarouselNext
            className="absolute top-1/2 -translate-y-1/2 right-4 z-20 disabled:hidden peer/next"
            variant="bg"
          />
          <div className="md:flex opacity-100 h-full w-1/2 bg-gradient-to-l from-white to-transparent absolute top-0 right-0 z-10 peer-disabled/next:opacity-0 transition-opacity ease-in-out pointer-events-none" />
        </Carousel>
      </S.CardsContainer>
      <S.Link field={slice.primary.cta}>{slice.primary.cta_text}</S.Link>
    </S.Container>
  );
};

export default MundorailClosedRoutes;
