"use client";

import { Content } from "@prismicio/client";
import { PrismicRichText, SliceComponentProps } from "@prismicio/react";
import * as S from "./styles";
import { useRouter } from "next/navigation";

/**
 * Props for `ClosedRoutesFullPage`.
 */
export type ClosedRoutesFullPageProps =
  SliceComponentProps<Content.ClosedRoutesFullpageSlice>;

/**
 * Component for "ClosedRoutesFullPage" Slices.
 */
const ClosedRoutesFullPage = ({
  slice
}: ClosedRoutesFullPageProps): JSX.Element => {
  const router = useRouter();

  return (
    <S.Container
      data-slice-type={slice.slice_type}
      data-slice-variation={slice.variation}
    >
      <S.TitleImg field={slice.primary.title} alt="" />
      <S.Subtitle>
        <PrismicRichText field={slice.primary.subtitle} />
      </S.Subtitle>
      <S.CardsContainer>
        {slice.primary.images.map(({ image, uid }, index) => (
          <S.Card
            key={index}
            field={image}
            onClick={() => router.push(`/${uid}`)}
            alt=""
          />
        ))}
      </S.CardsContainer>
      <S.Link field={slice.primary.cta}>{slice.primary.cta_text}</S.Link>
    </S.Container>
  );
};

export default ClosedRoutesFullPage;
