import * as React from "react";
import { Slot } from "@radix-ui/react-slot";
import { cva, type VariantProps } from "class-variance-authority";

import { cn } from "@/lib/utils";

const buttonVariants = cva(
  "text-white inline-flex items-center justify-center gap-2 whitespace-nowrap rounded-full text-sm tracking-[2px] ring-offset-brand transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-brand focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 [&_svg]:pointer-events-none [&_svg]:size-4 [&_svg]:shrink-0",
  {
    variants: {
      variant: {
        default:
          "border border-brand border-opacity-50 bg-none hover:border-opacity-100 hover:brightness-200 hover:font-semibold",
        bg: "border border-black text-black bg-black bg-opacity-30 hover:brightness-200 hover:font-semibold",
        contrast:
          "border border-black bg-none text-black hover:brightness-200 hover:font-semibold"
      },
      size: {
        default: "h-10 px-6 py-4",
        m: "h-[50px] px-[30px] py-[20px]"
      }
    },
    defaultVariants: {
      variant: "default",
      size: "default"
    }
  }
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : "button";
    return (
      <Comp
        className={cn(buttonVariants({ variant, size, className }))}
        ref={ref}
        {...props}
      />
    );
  }
);
Button.displayName = "Button";

export { Button, buttonVariants };
