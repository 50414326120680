import { PrismicNextImage, PrismicNextLink } from "@prismicio/next";
import styled from "styled-components";

export const Container = styled.div`
  max-width: 1280px;
  margin: auto;
  padding: 55px 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
  padding: 60px 0 32px;
  background-color: rgba(39, 189, 131, 0.03);
  border-radius: 40px;
  width: 100%;
  margin-bottom: 44px;
`;

export const TitleImg = styled(PrismicNextImage)`
  max-width: 360px;
`;

export const Subtitle = styled.div`
  max-width: 615px;
  font-weight: 500;
  font-size: 20px;
  color: #001159;

  @media only screen and (max-width: 768px) {
    text-align: center;
    margin-top: 24px;
  }

  strong {
    color: #27bd83;
  }
`;

export const CardsContainer = styled.div`
  display: flex;
  max-width: 1150px;
  margin: auto;
  overflow-x: hidden;

  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }

  @media only screen and (max-width: 768px) {
    max-width: 100%;
  }
`;

export const Card = styled(PrismicNextImage)`
  min-height: 360px;
  max-height: 360px;
  min-width: 325px;
  max-width: 325px;
`;

export const Link = styled(PrismicNextLink)`
  font-size: 25px;
  line-height: 30px;
  padding: 15px 30px;
  border: 2px solid white;
  border-radius: 1000px;
  cursor: pointer;
  width: max-content;
  margin-top: 64px;

  color: #001159;
  border-color: #001159;
`;
